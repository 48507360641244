import { AxiosError } from "axios";
import errorCat from 'i18n/cat/error';
import { FunusCustomError } from "./FunusCustomError";
import { FunusBackendError } from "./FunusBackendError";

export class BackendUnmanagedError extends FunusCustomError {
  constructor(error: AxiosError) {
    super(error);
    try {
      const backEndInfo = error.toJSON() as FunusBackendError;
      this.message = backEndInfo.trace || error.message || errorCat[500];
    } catch { }

    this.name = "BackendUnmanagedError";
  }
}
