import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/base/Modal';

import { FileManagerDeleteModalComponent } from './types';

export const FileManagerDeleteModal: FC<FileManagerDeleteModalComponent> = ({
  onAccept, onCancel, show, item,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      buttons={[
        {
          color: 'secondary',
          id: 'cancel',
          onClick: onCancel,
          text: t('common.cancel'),
        },
        {
          id: 'accept',
          onClick: onAccept,
          text: t('common.delete'),
        },
      ]}
      closeOnFocusLost={false}
      show={show}
      showCancel={false}
      title={t('common.delete')}
      onHide={onCancel}
    >
      {show && (<div>{`${t('common.delete')} ${item.name}`}</div>)}
    </Modal>
  );
};
