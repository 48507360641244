import { AxiosResponse } from "axios";
import { DashboardEnquiry, SaveEnquiry } from "models/Enquiry";
import { SearchIpProps } from "models/Ip";
import { SaveRecord } from "models/Record";

export type FunusApiResponse<T>  = Promise<AxiosResponse<T>>

export enum RecordInformationTypeFilter  {
  APPOINTMENT = 'APPOINTMENT',
  COLLECTION = 'COLLECTION',
  VIGIL = 'VIGIL',
  CEREMONY = 'CEREMONY',
  CREMATION = 'CREMATION',
  INTERMENT = 'INTERMENT'
}

export type RecordParams = {
  id: string | number;
  values: SaveRecord;
};


export type EnquiriesDashboardResponse = {
  introduced: DashboardEnquiry[];
  pending: DashboardEnquiry[];
  completed: DashboardEnquiry[];
};

export type RecordsServicesProps = {
  erpId?: number;
  deceasedName?: string;
  deceasedFirstSurname?: string;
  deceasedSecondSurname?: string;
  type: any;
  date?: string;
  deathType?: string;
};

export type ServiceInformationDto = {
  country: string;
  province: string;
  city: string;
  location: string;
  time: string;
  date: string;
};

export type PersonDto = {
  name: string;
  firstSurname: string;
  secondSurname: string;
};

export enum RecordServiceDtoType {
  CREMATION = 'CREMATION',
  INTERMENT = 'INTERMENT'
}
export enum RecordServiceDtoPreparationStatus {
  ON_GOING = 'ON_GOING',
  FINISHED = 'FINISHED'
} 

export type RecordServiceDto = {
  erpId: number;
  id: number;
  person: PersonDto;
  appointmentDateTime: string;
  appointmentDate?: string;
  appointmentTime?: string;
  collectionDateTime: string;
  vigil: ServiceInformationDto | null;
  ceremony: ServiceInformationDto | null;
  cremation: ServiceInformationDto | null;
  interment: ServiceInformationDto | null;
  type: RecordServiceDtoType;
  preparationStatus: RecordServiceDtoPreparationStatus
};

export type EnquiryParams = {
  id: string | number;
  values: SaveEnquiry;
};

export type IpParams = {
  id: string | number;
  values: SearchIpProps;
};

export type SearchClientParams = {
  identifier?: string;
  passport?: string;
  nie?: string;
  cif?: string;
};

interface ISortFilter {
  name: string;
  descending: boolean;
}

interface IPageFilter {
  size: number;
  index: number;
}

export interface IFilter<T> {
  filter: T[];
  page: IPageFilter;
  sort?: ISortFilter[];
}


enum RecordStateEnum {
  FINISHED = 'FINISHED',
  INVOICED = 'INVOICED',
  INVOICED_PENDING = 'INVOICED_PENDING',
  OPENED = 'OPENED',
}