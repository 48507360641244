export enum LogTypes {
  AI_CLIENT = 'AI_CLIENT',
  API_ALFRESCO = 'API_ALFRESCO',
  CATALINA = 'CATALINA',
  EKON = 'EKON',
  FUNUS = 'FUNUS',
  MAPPER = 'MAPPER',
  META_CLIENT = 'META_CLIENT',
  REST_CONTROLLER = 'REST_CONTROLLER',
  SERVICE = 'SERVICE'
}
