import React, { FC, useContext } from 'react';

import CustomIcon from 'components/base/CustomIcon';
import { OtherTypes } from 'components/base/CustomIcon/types';

import { FileManagerContext } from '../context';

import { FileManagerBreadCrumbComponent } from './FileManagerBreadCrumbComponent';

export const FileManagerBreadCrumb: FC<FileManagerBreadCrumbComponent> = () => {
  const { folderPath, onActiveItem, homeId } = useContext(FileManagerContext);

  return (
    <div className="f-file-manager-breadcrumb">
      <span
        aria-pressed="false"
        role="button"
        tabIndex={0}
        onClick={() => {
          onActiveItem({
            id: homeId,
            name: '',
            type: 'folder',
          });
        }}
        onKeyDown={() => false}
      >
        <CustomIcon icon={OtherTypes.HOME} />
      </span>
      {folderPath
        .slice(1)
        .map((pathItem) => (
          <span key={`filemanager-breadcrumb-${pathItem.id}`}>
            <span className="f-file-manager-breadcrumb-separator">/</span>
            <span
              aria-pressed="false"
              role="button"
              tabIndex={0}
              onClick={() => {
                onActiveItem(pathItem);
              }}
              onKeyDown={() => false}
            >
              {pathItem.name}
            </span>
          </span>
        ))}
    </div>
  );
};
