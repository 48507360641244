import { createContext } from 'react';

import { Nullable } from 'utils/types/Nullable/types';

import { FileManagerClipboard } from '../types/FileManagerClipboard';
import { FileManagerItem } from '../types/FileManagerItem';

export const FileManagerContext = createContext<{
  folderPath: Array<FileManagerItem>,
  onActiveItem:(item: FileManagerItem) => void;
  onDeleteItem: (id: string) => void;
  onCreateFolder: (newFolderName: string) => void;
  onRenameItem: (id: string, name: string) => void;
  onUploadFile: (file: File, fileName: string) => void;
  onDownloadFile: (item: FileManagerItem) => void;
  onCopy: (item: FileManagerItem) => void;
  onCut: (item: FileManagerItem) => void;
  onPaste: () => void;
  homeId: string;
  currentFolderId: string;
  selectedItem: Nullable<FileManagerItem>;
  disabled: boolean;
  clipboard: Nullable<FileManagerClipboard>;
    }>(
    {
      clipboard: null,
      currentFolderId: '',
      disabled: false,
      folderPath: [],
      homeId: '',
      onActiveItem: () => false,
      onCopy: () => false,
      onCreateFolder: () => false,
      onCut: () => false,
      onDeleteItem: () => false,
      onDownloadFile: () => false,
      onPaste: () => false,
      onRenameItem: () => false,
      onUploadFile: () => false,
      selectedItem: null,
    });
