import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Input from 'components/base/Input';
import Modal from 'components/base/Modal';

import { UploadFileModalComponent } from './types';

export const FileManagerUploadFileModal: FC<UploadFileModalComponent> = (
  { show, onCancel, onAccept },
) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<File>();
  const [fileName, setFileName] = useState<string>();

  useEffect(
    () => {
      setFileName(file?.name || '');
    },
    [file],
  );

  const handleAccept = useCallback(
    () => {
      if (file && fileName) {
        onAccept(file, fileName);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [file, fileName],
  );

  const handleCancel = useCallback(
    () => {
      setFileName(undefined);
      setFile(undefined);

      onCancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Modal
      buttons={[
        {
          id: 'cancel',
          onClick: handleCancel,
          text: t('common.cancel'),
        },
        {
          disabled: !file || !fileName,
          id: 'accept',
          onClick: handleAccept,
          text: t('common.accept'),
        },
      ]}
      closeOnFocusLost={false}
      show={show}
      showCancel={false}
      title={`${t('common.add')} ${t('common.document')}`}
      onHide={handleCancel}
    >
      {show && (
      <React.Fragment>
        <Input
          name="file-upload"
          placeholder={t('common.selectFile')}
          type="file"
          value={file?.name || ''}
          onChange={(val: File) => setFile(val)}
        />
        <Input
          disabled={!file}
          id="fileName"
          name="fileName"
          placeholder={t('common.name')}
          value={fileName || ''}
          onChange={(val: string) => setFileName(val)}
        />
      </React.Fragment>
      )}
    </Modal>
  );
};
