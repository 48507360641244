import classNames from 'classnames';
import React, { FC } from 'react';

import { IBaseComponent } from 'components/base/types';

interface FileManagerContainerItemComponent extends IBaseComponent {
  selected: boolean;
  onClick: () => void;
  onDoubleClick: () => void;
}

export const FileManagerContainerItem: FC<FileManagerContainerItemComponent> = (
  {
    children, selected, onClick, onDoubleClick,
  },
) => (
  <div
    aria-selected={selected}
    className={classNames('f-file-manager-container-item', { selected })}
    role="option"
  >
    <div
      aria-pressed="false"
      role="button"
      tabIndex={0}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onKeyDown={() => false}
    >
      {children}
    </div>
  </div>
);
