import classNames from 'classnames';
import React, {
  FC, useCallback, useState,
} from 'react';

import { Nullable } from 'utils/types/Nullable/types';

import { FileManagerActions } from './Actions';
import { FileManagerBreadCrumb } from './BreadCrumb';
import { FileManagerContainer } from './Container';
import { FileManagerContainerItem } from './ContainerItem';
import { FileManagerContext } from './context';
import { FileManagerItemWrapper } from './FileManagerItemWrapper';
import { FileManagerClipboard } from './types/FileManagerClipboard';
import { FileManagerComponent } from './types/FileManagerComponent';
import { FileManagerItem } from './types/FileManagerItem';

import './index.scss';

export const FileManager: FC<FileManagerComponent> = (
  {
    items,
    onActiveItem,
    homeId,
    onDeleteItem,
    currentFolderId,
    onCreateFolder,
    onRenameItem,
    onUploadFile,
    onDownloadFile,
    disabled = false,
    onCopy,
    onMove,
  },
) => {
  const [folderPath, setFolderPath] = useState<Array<FileManagerItem>>([{
    id: homeId,
    name: 'Home',
    type: 'folder',
  }]);
  const [selected, setSelected] = useState<Nullable<FileManagerItem>>(null);
  const [view] = useState<'icons-view' | 'icons-big-view' | 'detail-view'>('icons-view');

  const [clipboard, setClipboard] = useState<Nullable<FileManagerClipboard>>(null);

  const handleCopy = useCallback(
    (item: FileManagerItem) => {
      setClipboard({
        action: 'copy',
        node: item.id,
      });
      setSelected(null);
    },
    [],
  );

  const handleCut = useCallback(
    (item: FileManagerItem) => {
      setClipboard({
        action: 'cut',
        node: item.id,
      });
      setSelected(null);
    },
    [],
  );

  const handlePaste = useCallback(
    () => {
      if (clipboard) {
        setClipboard(null);
        if (clipboard.action === 'copy') {
          onCopy(clipboard.node, currentFolderId);
        } else if (clipboard.action === 'cut') {
          onMove(clipboard.node, currentFolderId);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clipboard, currentFolderId],
  );

  const updateFolderPath = useCallback(
    (item: FileManagerItem) => {
      const updatedFolderPath = folderPath.slice();
      const folderPathItemIdx = updatedFolderPath
        .findIndex((folderPathItem) => folderPathItem.id === item.id);

      if (folderPathItemIdx === -1) {
        updatedFolderPath.push(item);
        setFolderPath(updatedFolderPath.slice());
      } else {
        setFolderPath(updatedFolderPath.slice(0, folderPathItemIdx + 1));
      }
    },
    [folderPath],
  );

  const handleActiveItem = useCallback(
    (item: FileManagerItem) => {
      if (item.type === 'folder') {
        updateFolderPath(item);
        setSelected(null);
      }
      onActiveItem(item);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateFolderPath],
  );

  const handleRename = useCallback(
    (id: string, name: string) => {
      onRenameItem(id, name);
      setSelected(null);
    },
    [onRenameItem],
  );

  const handleDelete = useCallback(
    (id: string) => {
      onDeleteItem(id);
      setSelected(null);
    },
    [onDeleteItem],
  );

  return (
    <FileManagerContext.Provider
      value={{
        clipboard,
        currentFolderId,
        disabled,
        folderPath,
        homeId,
        onActiveItem: handleActiveItem,
        onCopy: handleCopy,
        onCreateFolder,
        onCut: handleCut,
        onDeleteItem: handleDelete,
        onDownloadFile,
        onPaste: handlePaste,
        onRenameItem: handleRename,
        onUploadFile,
        selectedItem: selected,
      }}
    >
      <div className={classNames('f-file-manager', view)}>
        <FileManagerBreadCrumb />
        <FileManagerActions />
        <FileManagerContainer>
          {items.map((folderItem) => (
            <FileManagerContainerItem
              key={folderItem.id}
              selected={folderItem.id === selected?.id}
              test-id={`file-manager-container-item-${folderItem.id}`}
              onClick={() => {
                setSelected(folderItem);
              }}
              onDoubleClick={() => {
                handleActiveItem(folderItem);
              }}
            >
              <FileManagerItemWrapper value={folderItem} />
            </FileManagerContainerItem>
          ))}
        </FileManagerContainer>
      </div>
    </FileManagerContext.Provider>
  );
};
