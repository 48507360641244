import axios, { AxiosError, AxiosResponse } from 'axios';

import storage from 'config/storage';
import { BackendUnmanagedError } from './apiErrors/BackendUnmanagedError';
import { ForbiddenError } from "./apiErrors/ForbiddenError";
import { TelevisionNotExistsError } from "./apiErrors/TelevisionNotExistsError";
import { GenericError } from "./apiErrors/GenericError";
import { ConflictError } from "./apiErrors/ConflictError";
import { MissingIncinerationAuthorizationError } from "./apiErrors/MissingIncinerationAuthorizationError";
import { UnprocessableEntityError } from "./apiErrors/UnprocessableEntityError";
import { ValidationError } from "./apiErrors/ValidationError";
import { LoginError } from "./apiErrors/LoginError";
import { EkonError } from "./apiErrors/EkonError";

export const StatusCode = {
  Unauthorized: 401,
  Forbidden: 403,
  Conflict: 409,
  UnprocessableEntity: 422,
  EkonError: 424,
  TooManyRequests: 429,
  InternalServerError: 500,
};

/* ~~~~~~~~~~ RESPONSE ~~~~~~~~~~ */

const onFulfilledResponse = (response: AxiosResponse) => {
  return response;
};

export const onRejectedResponse = (error: AxiosError) => {
  const errorInfo = error.toJSON() as any;
  const status = errorInfo.status

  if (status === StatusCode.InternalServerError ) {
    throw new BackendUnmanagedError(error)
  } else if (status === StatusCode.EkonError) {
    throw new EkonError(error);
  } else if (error.response?.data === 'television.not.exist') {
    throw new TelevisionNotExistsError(error)
  } else if (error.response?.data === 'cremation.documentation.empty') {
    throw new MissingIncinerationAuthorizationError(error)
  } else if (status === StatusCode.Unauthorized) {
    throw new LoginError(error)
  } else if (status === StatusCode.UnprocessableEntity) {
    throw new UnprocessableEntityError(error)
  } else if (status === StatusCode.Conflict) {
    throw new ConflictError(error)
  } else if (status === StatusCode.Forbidden)  {
    throw new ForbiddenError(error)
  } else if (error.response?.data?.errors) {
    throw new ValidationError(error)
  }

  throw new GenericError(error)
};

const initializeAxios = (token?: string) =>
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: token || storage.getToken() || '',
    },
  });

const axiosInstance = initializeAxios();

axiosInstance.interceptors.request.use(
  (config) => {
    const token = storage.getToken() || '';

    if (token && config.headers) {
      config.headers.Authorization = token;
    } else {
      delete axios.defaults.headers.common.Authorization;
    }
    return config;
  },

  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(onFulfilledResponse, onRejectedResponse);

export default axiosInstance;
