import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { showErrorToast, showSuccessToast } from 'utils/toasts';
import { usePermissions } from 'utils/usePermissions';

import { SimpleUserRequestPermissionNames } from 'config/apiFunus/generated/data-contracts';
import { settingsController } from 'modules/settings/api';
import { IFunusSettings } from 'modules/settings/models/IFunusSettings';
import { emptyFunusSettings } from 'modules/settings/pages/settings/emptyFunusSettings';

import { SettingsHook } from './SettingsHook';

export const useSettings = (): SettingsHook => {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();

  const [settings, setSettings] = useState<IFunusSettings>(emptyFunusSettings);

  const loadSettings = useCallback(
    () => {
      settingsController.GetSettings()
        .then((response) => {
          setSettings(response);
          return response;
        })
        .catch(() => showErrorToast(t('settings.loadError')));
    },
    [t],
  );

  const saveSettings = useCallback(
    (v: IFunusSettings) => {
      settingsController.UpdateSettings(v)
        .then(() => showSuccessToast(t('settings.saveSuccess')))
        .catch(() => showErrorToast(t('settings.saveError')));
    },
    [t],
  );

  return {
    isSettingsEnabled: hasPermission(SimpleUserRequestPermissionNames.WHATSAPP_SETTINGS)
            || hasPermission(SimpleUserRequestPermissionNames.BACK_LOGS),
    loadSettings,
    saveSettings,
    settings,

  };
};
