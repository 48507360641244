const settings = {
  blocks: {
    BACK_LOGS: 'Logs',
    WHATSAPP_SETTINGS: 'WhatsApp',
  },
  loadError: 'Error en obtenir la configuració',
  logs: {
    hostname: 'Servidor',
    lines: 'Lineas',
    log: 'Log',
    type: 'Tipus',
    types: {
      AI_CLIENT: 'AI_CLIENT',
      API_ALFRESCO: 'API_ALFRESCO',
      CATALINA: 'CATALINA',
      EKON: 'EKON',
      FUNUS: 'FUNUS',
      MAPPER: 'MAPPER',
      META_CLIENT: 'META_CLIENT',
      REST_CONTROLLER: 'REST_CONTROLLER',
      SERVICE: 'SERVICE',
    },
  },
  saveError: 'Error al guardar la configuración',
  saveSuccess: 'Configuración guardada',
  whatsapp: {
    authToken: 'Token de autenticación',
    destinationNumberPhone: 'Teléfono de destino',
    language: 'Idioma',
    sourceNumberPhone: 'Número de teléfono de origen',
    templateName: 'Nombre de la plantilla',
    test: 'Probar configuración',
  },
};

export default settings;
