import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/base/Modal';
import { PdfViewer } from 'components/base/PdfViewer';

import { MimeTypes } from 'models/Base64Data/MimeTypes';

import { IDocumentViewerModal } from './IDocumentViewerModal';

const imageMimeTypes = [MimeTypes.IMAGE_JPG, MimeTypes.IMAGE_JPEG, MimeTypes.IMAGE_PNG];
export const DocumentViewerModal: FC<IDocumentViewerModal> = ({
  onAccept, onCancel, show, mimeType, content,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      buttons={[
        {
          id: 'accept',
          onClick: onAccept,
          text: t('common.close'),
        },
      ]}
      closeOnFocusLost={false}
      show={show}
      showCancel={false}
      title={t('common.document')}
      onHide={onCancel}
    >
      {show && (
      <React.Fragment>
        {mimeType === MimeTypes.APPLICATION_PDF && (
        <PdfViewer
          base64={content}
          onLoadSuccess={() => false}
        />
        )}
        {imageMimeTypes.includes(mimeType) && (
        <img
          alt="preview"
          src={content}
        />
        )}
      </React.Fragment>
      )}
    </Modal>
  );
};
