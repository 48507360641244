import { Declarant } from "models/Declarant";
import { ContentType, HttpClient, RequestParams } from "../generated/http-client";
import { AlfrescoNodeId } from "./AlfrescoNodeId";
import { ErpId } from "models/ErpId";
import { AlfrescoNode } from "./AlfrescoNode";
import { FileManagerItem } from "components/base/FileManager/types/FileManagerItem";
import { RecordId } from "models/RecordId";
import { NodeChildAssociationPaging } from "models/Alfresco/NodeChildAssociationPaging";
import { arrayBufferToBase64 } from "modules/budget/hooks/useBudgetPdf/helpers";
import { MimeTypes } from "models/Base64Data/MimeTypes";

export class AlfrescoController<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  // https://prefunus.cabrejunqueras.cat/api-explorer/#!/nodes/deleteNode
  deleteFile = (nodeId: AlfrescoNodeId, params: RequestParams = {}) =>
    this.http.request<void, void>({
      path: `/alfresco/nodes/${nodeId}`,
      method: "DELETE",
      secure: true,
      type: ContentType.Json,
      ...params,
    });

  // https://prefunus.cabrejunqueras.cat/api-explorer/#!/nodes/getNodeContent
  getFile = (nodeId: AlfrescoNodeId, params: RequestParams = {}) =>
    this.http.request<void, void>({
      path: `/alfresco/document/${nodeId}`,
      method: "GET",
      secure: true,
      type: ContentType.Json,
      ...params,
    });

  // https://prefunus.cabrejunqueras.cat/api-explorer/#!/nodes/copyNode
  copyNode = (data: {
    nodeId: AlfrescoNodeId,
    parentId: AlfrescoNodeId
  }, params: RequestParams = {}) =>
    this.http.request<void, void>({
      path: `/alfresco/nodes/${data.nodeId}/copy`,
      method: "POST",
      body: {
        targetParentId: data.parentId
      },
      secure: true,
      type: ContentType.Json,
      ...params,
    });

  // https://prefunus.cabrejunqueras.cat/api-explorer/#!/nodes/moveNode
  moveNode = (data: {
    nodeId: AlfrescoNodeId,
    parentId: AlfrescoNodeId
  }, params: RequestParams = {}) =>
    this.http.request<void, void>({
      path: `/alfresco/nodes/${data.nodeId}/move`,
      method: "POST",
      body: {
        targetParentId: data.parentId
      },
      secure: true,
      type: ContentType.Json,
      ...params,
    });

  renameNode = (data: {
    nodeId: AlfrescoNodeId,
    parentId: AlfrescoNodeId,
    name: string;
  }, params: RequestParams = {}) =>
    this.http.request<void, void>({
      path: `/alfresco/nodes/${data.nodeId}/move`,
      method: "POST",
      body: {
        targetParentId: data.parentId,
        name: data.name
      },
      secure: true,
      type: ContentType.Json,
      ...params,
    });

  uploadFile = (data: {
    file: File,
    fileName: string,
    nodeId: AlfrescoNodeId,
  }, params: RequestParams = {}) => {
    const formData = new FormData();
    formData.append('name', data.fileName);
    formData.append('filedata', data.file);
    formData.append('nodeType', 'cm:content');

    return this.http.request<void, void>({
      path: `/alfresco/nodes/${data.nodeId}/children/upload`,
      method: "POST",
      secure: true,
      body: formData,
      headers: { 'content-type': 'multipart/form-data' },
      ...params,
    })
  };

  getChildren = (nodeId: AlfrescoNodeId, params: RequestParams = {}): Promise<Array<FileManagerItem>> =>
    this.http.request<NodeChildAssociationPaging, void>({
      path: `/alfresco/nodes/${nodeId}/children`,
      method: "GET",
      secure: true,
      type: ContentType.Json,
      ...params,
    })
      .then((response) => {
        return response.list?.entries
          ?.map((node) => node.entry)
          .map((node) => {
            return {
              id: node.id,
              name: node.name,
              type: node.isFolder ? 'folder' : 'file',
              mimeType: node.content?.mimeType,
            }
          }) || []
      })

  createFolder = (data: {
    nodeId: AlfrescoNodeId;
    name: string;
  }, params: RequestParams = {}): Promise<Array<FileManagerItem>> =>
    this.http.request<NodeChildAssociationPaging, void>({
      path: `/alfresco/nodes/${data.nodeId}/children`,
      method: "POST",
      body: {
        name: data.name,
        nodeType: 'cm:folder'
      },
      secure: true,
      type: ContentType.Json,
      ...params,
    })
      .then((response) => {
        return response.list?.entries
          ?.map((node) => node.entry)
          .map((node) => {
            return {
              id: node.id,
              name: node.name,
              type: node.isFolder ? 'folder' : 'file',
              mimeType: node.content?.mimeType,
            }
          }) || []
      })

  getFileContent = (nodeId: AlfrescoNodeId, mimeType: MimeTypes, params: RequestParams = {}): Promise<string> =>
    this.http.request<ArrayBuffer, void>({
      path: `/alfresco/nodes/${nodeId}/content`,
      format: 'arraybuffer',
      method: "GET",
      secure: true,
      type: ContentType.Json,
      ...params,
    })
      .then((response) => {
        return arrayBufferToBase64(response, mimeType);
      })
}
