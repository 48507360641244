import { AxiosResponse } from 'axios';
import { BaseWorkOrderType } from 'models/Workorder';
import {
  IFilter} from './types';
import { record } from './record'
import { documents } from './document'
import { adviser } from './adviser'
import { enquiries } from './enquiries'
import { login } from './login'
import { masterData } from './masterData'
import { sale } from './sale'
import { ips } from './ips'
import { users } from './users'
import { permission } from './permission'
import { roles } from './roles'
import { workOrder } from './workOrder'
import { notifications } from './notifications'
import { qr } from './qr'
import axiosInstance from 'config/axios';
import {HttpClient} from './generated/http-client';
import { RecordController } from './generated/RecordController';
import { AdviceController } from './generated/AdviceController';
import { OrderLineController } from './OrderLine';
import { MasterController } from './generated/MasterController';
import { DeclarantNotificacionsController } from './DeclarantNotifications';
import { AlfrescoController } from './Alfresco';
import { DeliveryObjectsController } from './DeliveryObjects';

export type findPublicWorkOrdersDetailResponse = {
  pending: BaseWorkOrderType[];
  inProgress: BaseWorkOrderType[];
};

export const defaultFilter: IFilter<any> = {
  filter: [],
  page: { size: 10, index: 0 },
};

export const modifiedResponse: AxiosResponse = {
  data: {},
  status: 200,
  statusText: 'OK',
  headers: {},
  config: {},
};

const apiFunus = {
  adviser,
  documents,
  enquiries,
  ips,
  login,
  masterData,
  permission,
  record,
  roles,
  sale,
  users,
  workOrder,
  qr,
  notifications,
};

export default apiFunus;


const httpClient = new HttpClient();
httpClient.instance = axiosInstance;
export const masterController = new MasterController(httpClient);
export const orderLineController = new OrderLineController(httpClient);
export const recordController = new RecordController(httpClient);
export const adviceController = new AdviceController(httpClient);
export const declarantNotificationsController = new DeclarantNotificacionsController(httpClient);
export const alfrescoController = new AlfrescoController(httpClient);
export const deliveryObjectsController = new DeliveryObjectsController(httpClient);
