const settings = {
  blocks: {
    BACK_LOGS: 'Logs',
    WHATSAPP_SETTINGS: 'WhatsApp',
  },
  loadError: 'Error en obtenir la configuració',
  logs: {
    hostname: 'Servidor',
    lines: 'Línies',
    log: 'Log',
    type: 'Tipus',
    types: {
      AI_CLIENT: 'AI_CLIENT',
      API_ALFRESCO: 'API_ALFRESCO',
      CATALINA: 'CATALINA',
      EKON: 'EKON',
      FUNUS: 'FUNUS',
      MAPPER: 'MAPPER',
      META_CLIENT: 'META_CLIENT',
      REST_CONTROLLER: 'REST_CONTROLLER',
      SERVICE: 'SERVICE',
    },
  },
  saveError: 'Error en desar la configuració',
  saveSuccess: 'Configuració guardada',
  whatsapp: {
    authToken: 'Token d\'autenticació',
    destinationNumberPhone: 'Telèfon de destinació',
    language: 'Idioma',
    sourceNumberPhone: 'Número de telèfon d\'origen',
    templateName: 'Nom de la plantilla',
    test: 'Provar configuració',
    testError: 'Error enviant missatge',
    testSuccess: 'Missatge enviat correctament',
  },
};

export default settings;
