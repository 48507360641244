import classNames from 'classnames';
import { isNaN } from 'lodash';
import React, {
  FC, useCallback, useState,
} from 'react';
import { Loader } from 'react-feather';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import Input from 'components/base/Input';
import Select from 'components/base/Select';

import { LogTypes } from 'modules/backen-logs/api/LogsClient/LogTypes';
import { useLogs } from 'modules/backen-logs/hooks/useLogs';

import './index.scss';

function isValidNumber(str: string) {
  if (typeof str !== 'string') {
    return false;
  }

  if (str.trim() === '') {
    return false;
  }

  return !isNaN(str) && !isNaN(parseFloat(str));
}

export const BackLogsPanel: FC<Record<string, unknown>> = () => {
  const { t } = useTranslation();
  const {
    fetchLogs, log, loading, hostname,
  } = useLogs();
  const [type, setType] = useState<LogTypes>(LogTypes.SERVICE);
  const [lines, setLines] = useState<string>('50');

  const handleShow = useCallback(
    () => {
      fetchLogs(type, Number(lines));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lines, type],
  );

  return (
    <div className="back-logs-panel">
      <div className="back-logs-panel-form">
        <Select<LogTypes>
          getLabel={(value) => t(`settings.logs.types.${value}`)}
          getValue={(value) => value}
          limit={15}
          name="log-type"
          options={Object.values(LogTypes)}
          placeholder={t('settings.logs.type')}
          testid="provinces-selector"
          value={type}
          searchable
          onChange={(v: LogTypes) => setType(v)}
        />
        <Input
          id="log-lines"
          name="log-lines"
          placeholder={t('settings.logs.lines')}
          value={lines}
          onChange={(val: string) => setLines(val)}
        />
        <Button
          disabled={loading || !lines || !isValidNumber(lines)}
          text={t('common.show')}
          onClick={handleShow}
        />
        {hostname && (<div className="back-logs-panel-form-hostname">{`${t('settings.logs.hostname')}: ${hostname}`}</div>)}
      </div>
      {loading && <div className="loading-panel"><Loader /></div>}
      {!loading && (
      <div className="back-logs-panel-logs-wrapper">
        {log.map((i, idx) => {
          const key = `log-line-${idx}`;
          return (
            <div
              key={key}
              className={classNames('back-logs-panel-logs-item', { pair: !!(idx % 2) }, { odd: !(idx % 2) })}
            >
              <div
                className="back-logs-panel-logs-item-data"
              // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: i,
                }}
              />
            </div>
          );
        })}
      </div>
      )}
    </div>
  );
};
