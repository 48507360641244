import classNames from 'classnames';
import React, { FC, useContext, useState } from 'react';
import { Download, PlusCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';

import Button from 'components/base/Button';
import CustomIcon from 'components/base/CustomIcon';
import { OtherTypes } from 'components/base/CustomIcon/types';

import { FileManagerContext } from '../context';
import { FileManagerCreateFolderModal } from '../modals/createFolder';
import { FileManagerDeleteModal } from '../modals/delete';
import { FileManagerRenameModal } from '../modals/rename';
import { FileManagerUploadFileModal } from '../modals/uploadFile';

export const FileManagerActions: FC<{
  showLabels?: boolean;
}> = ({ showLabels = false }) => {
  const {
    selectedItem,
    onDeleteItem,
    onCreateFolder,
    onRenameItem,
    onUploadFile,
    onDownloadFile,
    onCopy,
    onCut,
    clipboard,
    onPaste,
  } = useContext(FileManagerContext);
  const { t } = useTranslation();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showCreateFolderModal, setShowCreateFolderModal] = useState<boolean>(false);
  const [showRenameModal, setShowRenameModal] = useState<boolean>(false);
  const [showUploadFileModal, setShowUploadFileModal] = useState<boolean>(false);

  return (
    <React.Fragment>
      <div className={classNames('f-file-manager-actions', { 'show-labels': showLabels })}>
        <div className="f-file-manager-actions-button-group">
          <Button
            color="secondary"
            leftAddon={<CustomIcon icon={OtherTypes.FOLDER_PLUS} />}
            text={t('record.document.createFolder')}
            onClick={() => {
              setShowCreateFolderModal(true);
            }}
          />
          <Button
            color="secondary"
            leftAddon={<PlusCircle />}
            text={`${t('common.add')} ${t('common.document')}`}
            onClick={() => {
              setShowUploadFileModal(true);
            }}
          />
        </div>
        <div className="f-file-manager-actions-button-group">
          <Button
            color="secondary"
            disabled={!selectedItem}
            leftAddon={<CustomIcon icon={OtherTypes.DELETE} />}
            text={t('common.delete')}
            onClick={() => {
              setShowDeleteModal(true);
            }}
          />
          <Button
            color="secondary"
            disabled={!selectedItem}
            leftAddon={<CustomIcon icon={OtherTypes.EDIT} />}
            text={t('record.document.rename')}
            onClick={() => {
              setShowRenameModal(true);
            }}
          />
        </div>
        <div className="f-file-manager-actions-button-group">
          <Button
            color="secondary"
            disabled={!selectedItem}
            leftAddon={<CustomIcon icon={OtherTypes.COPY} />}
            text={t('common.copy')}
            onClick={() => {
              if (selectedItem) {
                onCopy(selectedItem);
              }
            }}
          />
          <Button
            color="secondary"
            disabled={!selectedItem}
            leftAddon={<CustomIcon icon={OtherTypes.CUT} />}
            text={t('common.cut')}
            onClick={() => {
              if (selectedItem) {
                onCut(selectedItem);
              }
            }}
          />
          <Button
            color="secondary"
            disabled={!clipboard}
            leftAddon={<CustomIcon icon={OtherTypes.PASTE} />}
            text={t('common.paste')}
            onClick={() => {
              onPaste();
            }}
          />
        </div>
        <div className="f-file-manager-actions-button-group">
          <Button
            color="secondary"
            disabled={!selectedItem || (!!selectedItem && selectedItem.type !== 'file')}
            leftAddon={<Download />}
            text={t('record.document.download')}
            onClick={() => {
              if (selectedItem) {
                onDownloadFile(selectedItem);
              }
            }}
          />
        </div>
      </div>
      {selectedItem && (
      <FileManagerDeleteModal
        item={selectedItem}
        show={showDeleteModal}
        onAccept={() => {
          setShowDeleteModal(false);
          onDeleteItem(selectedItem.id);
        }}
        onCancel={() => setShowDeleteModal(false)}
      />
      )}
      <FileManagerCreateFolderModal
        show={showCreateFolderModal}
        onAccept={(name: string) => {
          onCreateFolder(name);
          setShowCreateFolderModal(false);
        }}
        onCancel={() => {
          setShowCreateFolderModal(false);
        }}
      />
      {
        selectedItem && (
        <FileManagerRenameModal
          show={showRenameModal}
          onAccept={(newName: string) => {
            onRenameItem(selectedItem.id, newName);
            setShowRenameModal(false);
          }}
          onCancel={() => {
            setShowRenameModal(false);
          }}
        />
        )
      }
      <FileManagerUploadFileModal
        show={showUploadFileModal}
        onAccept={(file, filename) => {
          onUploadFile(file, filename);
          setShowUploadFileModal(false);
        }}
        onCancel={() => { setShowUploadFileModal(false); }}
      />
    </React.Fragment>
  );
};
