import {
  ContentType,
  HttpClient,
  RequestParams,
} from 'config/apiFunus/generated/http-client';
import { LogsResonse } from 'modules/backen-logs/hooks/useLogs/LogsResonse';

import { ApiLogsResponse } from './ApiLogsResonse';
import { LogTypes } from './LogTypes';

export class LogsClient<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  fetchLogs = async (
    {
      type,
      lines,
    }: {
      type: LogTypes,
      lines: number
    },
    params: RequestParams = {},
  ): Promise<LogsResonse> => this.http
    .request<ApiLogsResponse, void>({
      body: {
        type,
        lines,
      },
      method: 'POST',
      path: '/logs',
      secure: true,
      type: ContentType.Json,
      ...params,
    })
    .then((response) => ({
      logs: response.content.split(/\r\n|\r|\n/),
      hostname: response.hostname,
    }))
}
