import { FormikProps } from 'formik';
import React, {
  FC, useCallback,
  useEffect,
  useState,
} from 'react';

import { showErrorToast } from 'utils/toasts';

import { FileManager } from 'components/base/FileManager';
import { DocumentViewerModal } from 'components/base/FileManager/DocumentViewerModal';
import { FileManagerItem } from 'components/base/FileManager/types/FileManagerItem';

import { AlfrescoNodeId } from 'config/apiFunus/Alfresco/AlfrescoNodeId';
import { useAlfresco } from 'hooks/useAlfresco';
import { MimeTypes } from 'models/Base64Data/MimeTypes';
import { SaveRecord } from 'models/Record';

type DocumentFieldsProps = {
  disabled?: boolean;
  formikProps: FormikProps<SaveRecord>;
};
/** {
  disabled,
  formikProps: { errors, handleBlur, isSubmitting, setFieldValue, values },
} */
const DocumentsFields: FC<DocumentFieldsProps> = ({
  disabled,
  formikProps,
}) => {
  const {
    copyNode,
    createFolder,
    currentFolderChildren,
    currentFolderId,
    deleteItem,
    downloadFile,
    moveNode,
    rename,
    setCurrentFolderId,
    uploadFile,
    getFileContent,
  } = useAlfresco();

  const [previewContent, setPreviewContent] = useState<string>('');
  const [previewMimeType, setpreviewMimeType] = useState<MimeTypes>();
  const [showPreview, setShowPreview] = useState<boolean>(false);

  useEffect(
    () => {
      setCurrentFolderId(formikProps.values.alfrescoNodeId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleUpload = useCallback(
    (file, fileName) => {
      if (currentFolderId) {
        uploadFile(currentFolderId, file, fileName);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentFolderId],
  );

  const handleDelete = useCallback(
    (id: AlfrescoNodeId): void => {
      deleteItem(id);
    },
    [deleteItem],
  );

  const handleDownload = useCallback(
    (item: FileManagerItem): void => {
      if (item.mimeType) {
        downloadFile(item.id, item.mimeType, item.name);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleCreateFolder = useCallback(
    (name: string): void => {
      createFolder(currentFolderId, name);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentFolderId],
  );

  const handleRename = useCallback(
    (nodeId: string, name: string): void => {
      rename(nodeId, name);
    },
    [rename],
  );

  const getFile = useCallback(
    async (item: FileManagerItem) => {
      try {
        if (item.mimeType) {
          const base64 = await getFileContent(item.id, item.mimeType);
          setPreviewContent(base64);
          setpreviewMimeType(item.mimeType);
          setShowPreview(true);
        }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        showErrorToast(e.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <React.Fragment>
      <fieldset>
        <FileManager
          currentFolderId={currentFolderId}
          disabled={disabled}
          homeId={formikProps.values.alfrescoNodeId || ''}
          items={currentFolderChildren || []}
          onActiveItem={(item) => {
            if (item.type === 'folder') {
              setCurrentFolderId(item.id);
            } else if (item.type === 'file') {
              if (item.mimeType
                && [
                  MimeTypes.APPLICATION_PDF,
                  MimeTypes.IMAGE_JPEG,
                  MimeTypes.IMAGE_JPG,
                  MimeTypes.IMAGE_PNG,
                ].includes(item.mimeType)) {
                getFile(item);
              } else {
                // eslint-disable-next-line no-console
                console.warn('Unknown mime type: ', item.mimeType);
              }
            }
          }}
          onCopy={(nodeId, targetid) => {
            copyNode(nodeId, targetid);
          }}
          onCreateFolder={(name) => {
            handleCreateFolder(name);
          }}
          onDeleteItem={handleDelete}
          onDownloadFile={(item) => {
            handleDownload(item);
          }}
          onMove={(nodeId, targetid) => {
            moveNode(nodeId, targetid);
          }}
          onRenameItem={handleRename}
          onUploadFile={handleUpload}
        />
      </fieldset>
      {previewMimeType && (
      <DocumentViewerModal
        content={previewContent}
        mimeType={previewMimeType}
        show={showPreview}
        onAccept={() => setShowPreview(false)}
        onCancel={() => setShowPreview(false)}
      />
      )}
    </React.Fragment>
  );
};

export default DocumentsFields;
