import classNames from 'classnames';
import React, {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { usePermissions } from 'utils/usePermissions';

import { SimpleUserRequestPermissionNames } from 'config/apiFunus/generated/data-contracts';

import { ISettingsTabs } from './ISettingsTabs';
import { SettingsTabOptions } from './SettingsTabOptions';

import './index.scss';

const settings: Array<SettingsTabOptions> = [
  SimpleUserRequestPermissionNames.WHATSAPP_SETTINGS,
  SimpleUserRequestPermissionNames.BACK_LOGS,
];

export const SettingsTabs: FC<ISettingsTabs> = ({ onChange, value }) => {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  const [initialTabSetted, setInitialTabSetted] = useState<boolean>(false);

  const handleClick = useCallback(
    (v: SettingsTabOptions) => {
      onChange(v);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => {
      if (!initialTabSetted) {
        const firtsTab = settings.find((i) => hasPermission(i));
        if (firtsTab) {
          onChange(firtsTab);
          setInitialTabSetted(true);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasPermission, initialTabSetted],
  );

  const settingsForms = useMemo(
    () => settings
      .filter((i) => hasPermission(i))
      .map((settingsBlock, index) => (
        <div
          key={settingsBlock}
          className={classNames('f-settings-tabs-item', { active: settingsBlock === value })}
          role="tab"
          tabIndex={index}
          onClick={() => {
            handleClick(settingsBlock);
          }}
          onKeyDown={() => false}
        >
          {t(`settings.blocks.${settingsBlock}`)}
        </div>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasPermission, settings, t, value],
  );

  return (
    <div className="f-settings-tabs">
      {settingsForms}
    </div>
  );
};
