import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { showErrorToast } from 'utils/toasts';

import { LogsController } from 'modules/backen-logs/api';

import { LogCollection } from './LogCollection';
import { LogsHook } from './LogsHook';

export const useLogs = (): LogsHook => {
  const { t } = useTranslation();

  const [log, setLog] = useState<LogCollection>([]);
  const [hostname, setHostname] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const fetchLogs = useCallback(
    (type, lines) => {
      setLoading(true);
      setLog([]);
      setHostname('');
      LogsController
        .fetchLogs({
          lines, type,
        })
        .then((response) => {
          setLog(response.logs);
          setHostname(response.hostname);
          setLoading(false);
          return response;
        })
        .catch((e) => {
          showErrorToast(t(e.message));
          setLoading(false);
        });
    },
    [t],
  );

  return {
    fetchLogs,
    hostname,
    loading,
    log,
  };
};
