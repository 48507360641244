import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { showErrorToast, showSuccessToast } from 'utils/toasts';

import { FileManagerItem } from 'components/base/FileManager/types/FileManagerItem';

import { alfrescoController } from 'config/apiFunus';
import { AlfrescoNodeId } from 'config/apiFunus/Alfresco/AlfrescoNodeId';
import { Base64Data } from 'models/Base64Data';
import { MimeTypes } from 'models/Base64Data/MimeTypes';

import { AlfrescoHook } from './types';

export const useAlfresco = (): AlfrescoHook => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [items, setItems] = useState<Array<FileManagerItem>>([]);
  const [currentFolderId, setCurrentFolderId] = useState<AlfrescoNodeId>('');

  useEffect(
    () => {
      if (currentFolderId) loadNodeChildren(currentFolderId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentFolderId],
  );

  const deleteItem = useCallback(
    (nodeId: AlfrescoNodeId) => {
      setLoading(true);

      return alfrescoController
        .deleteFile(nodeId)
        .then((response) => {
          showSuccessToast(t('record.document.removeOk'));
          setLoading(false);
          loadNodeChildren(currentFolderId);
          return response;
        })
        .catch((error) => {
          showErrorToast(`${t('record.document.removeKo')}: ${t(error.message)}`);
          setLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentFolderId, t],
  );

  const uploadFile = useCallback((nodeId, file, fileName) => {
    setLoading(true);

    return alfrescoController
      .uploadFile({
        file, fileName, nodeId,
      })
      .then((response) => {
        showSuccessToast(t('record.document.uploadOk'));
        setLoading(false);
        if (currentFolderId) loadNodeChildren(currentFolderId);
        return response;
      })
      .catch((error) => {
        showErrorToast(`${t('record.document.uploadKo')}: ${t(error.message)}`);
        setLoading(false);
      });
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [currentFolderId, t]);

  const downloadFile = useCallback(
    async (nodeId: AlfrescoNodeId, type: string, name: string) => {
      try {
        const res = await alfrescoController.getFile(nodeId);

        const tempLink = document.createElement('a');
        tempLink.href = `data:${type}${Base64Data.BASE64_GLU}${res}`;
        tempLink.download = name;
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        showErrorToast(`${t('record.document.downloadKo')}: ${t(error.message)}`);
      }
    },
    [t],
  );

  const loadNodeChildren = useCallback((nodeId: AlfrescoNodeId) => {
    alfrescoController
      .getChildren(nodeId)
      .then((response) => {
        setItems(response);
        return response;
      })
      .catch((error) => {
        showErrorToast(`${t('record.document.loadContentKo')}: ${t(error.message)}`);
      });
  }, [t]);

  const copyNode = useCallback(
    (nodeId: AlfrescoNodeId, parentNodeId: AlfrescoNodeId) => {
      alfrescoController.copyNode({
        nodeId,
        parentId: parentNodeId,
      }).then((r) => {
        if (currentFolderId) loadNodeChildren(currentFolderId);
        return r;
      }).catch((error) => {
        showErrorToast(`${t('record.document.copyKo')}: ${t(error.message)}`);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentFolderId, t],
  );

  const moveNode = useCallback(
    (nodeId: AlfrescoNodeId, parent: AlfrescoNodeId) => {
      alfrescoController.moveNode({
        nodeId,
        parentId: parent,
      }).then((r) => {
        if (currentFolderId) loadNodeChildren(currentFolderId);
        return r;
      }).catch((error) => {
        showErrorToast(`${t('record.document.moveKo')}: ${t(error.message)}`);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentFolderId, t],
  );

  const renameNode = useCallback(
    (nodeId: AlfrescoNodeId, name: string) => {
      alfrescoController.renameNode({
        name,
        nodeId,
        parentId: currentFolderId,
      }).then((r) => {
        if (currentFolderId) loadNodeChildren(currentFolderId);
        return r;
      }).catch((error) => {
        showErrorToast(`${t('record.document.renameKo')}: ${t(error.message)}`);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentFolderId, t],
  );

  const createFolder = useCallback(
    (parentNodeId: AlfrescoNodeId, name: string) => {
      alfrescoController.createFolder({
        name,
        nodeId: parentNodeId,
      }).then((r) => {
        if (currentFolderId) loadNodeChildren(currentFolderId);
        return r;
      }).catch((error) => {
        showErrorToast(`${t('record.document.createFolderKo')}: ${t(error.message)}`);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentFolderId, t],
  );

  const getFileContent = useCallback(
    (id: string, mimeType: MimeTypes): Promise<string> => alfrescoController
      .getFileContent(id, mimeType)
      .then((response) => response)
      .catch((error) => {
        showErrorToast(`${t('record.document.getContentKo')}: ${t(error.message)}`);

        throw new Error(error);
      }),
    [t],
  );

  return {
    copyNode,
    createFolder,
    currentFolderChildren: items,
    currentFolderId,
    deleteItem,
    downloadFile,
    getFileContent,
    loading,
    moveNode,
    rename: renameNode,
    setCurrentFolderId,
    uploadFile,
  };
};
