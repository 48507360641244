const role = {
  delete: 'Eliminar rol',
  duplicated: 'Error, duplicat',
  fakeEmail: 'Error, email incorrecte',
  noDescription: 'Sense descripció',
  permissions: {
    ADVICE_ADD: 'AFEGIR SERVIS',
    ADVICE_INFO: 'VISUALITZAR INFORMATIU',
    ADVICE_PERMISSION_GROUP: 'Permisos relacionats amb els serveis d\'un expedient',
    ADVICE_READ: 'VISUALITZAR SERVEIS',
    ADVICE_WRITE: 'MODIFICAR SERVEIS',
    APPOINTMENT: 'ASSIGNAR DATA DE CITA PREVIA',
    ARTICLE_ADD: 'AFEGIR FLORS',
    ARTICLE_CANCEL: 'ANULAR COMANDES',
    ARTICLE_DELETE: 'ELIMINAR COMANDA FLORS',
    ARTICLE_DISAGREEMENT: 'CREAR DISCONFORMITAT',
    ARTICLE_NOTIFY: 'NOTIFICACIÓ DE FLOR AL CARRO',
    ARTICLE_PERMISSION_GROUP: 'Permisos de Flors',
    ARTICLE_QR_READ: 'LLEGIR QR FLORS',
    ARTICLE_READ: 'VISUALITZAR PANTALLA FLORS',
    ARTICLE_SUPPLIER: 'PERMIS DEL PROVEÏDOR PER ACCEPTAR',
    ASHES_DELIVERY: 'ENTREGAR CENDRES',
    BACK_LOGS: 'VEURE LOGS',
    BUDGET_ARTICLE_MANAGEMENT: 'MODIFICAR ARTICLES DE PRESSUPOSTOS',
    BUDGET_PERMISSION_GROUP: 'Permisos de pressupost',
    BUDGET_RULES_WRITE: 'ADMINISTRAR ARTICLES',
    BUDGET_WRITE: 'CREAR PRESSUPOSTOS',
    COLLECTION_NOTIFY: 'NOTIFICACIÓ DE RECOLLIDDA',
    ENQUIRY_ADD: 'AFEGIR CONSULTES',
    ENQUIRY_ASSIGN: 'ASSIGNAR CONSULTES',
    ENQUIRY_NOTIFY: 'NOTIFICACIÓ DE NOVA CONSULTA',
    ENQUIRY_PERMISSION_GROUP: 'Permisos de Consultes                      ',
    ENQUIRY_READ: 'VISUALITZAR CONSULTES',
    ENQUIRY_WRITE: 'MODIFICAR CONSULTES',
    NOTIFY_PERMISSION_GROUP: 'Permisos de Notificacions',
    RECORD_ADD: 'AFEGIR EXPEDIENTS',
    RECORD_ADD_ENQUIRY: 'CREAR EXPEDIENTS DE PRENECESITAT',
    RECORD_ADD_NOTICE: 'AFEGIR EXPEDIENTS AMB POQUES DADES',
    RECORD_ASSIGN: 'ASSIGNAR ASSESSORS A UN EXPEDIENT',
    RECORD_DELETE: 'ELIMINAR EXPEDIENT',
    RECORD_NOTIFY: 'NOTIFICACIÓ DE NOU EXPEDIENT',
    RECORD_PERMISSION_GROUP: 'Permisos relacionats amb expedients',
    RECORD_QR_READ: 'CONSULTAR QR',
    RECORD_READ: 'VISUALITZAR EXPEDIENTS',
    RECORD_WRITE: 'MODIFICAR EXPEDIENTS',
    SETTINGS_PERMISSION_GROUP: 'CONFIGURACIONS',
    USER_ADD: 'AFEGIR USUARI',
    USER_PERMISSION_GROUP: 'Permisos d\'administrador',
    USER_READ: 'VISUALITZAR ADMINISTRADOR',
    USER_WRITE: 'MODIFICAR USUARI',
    WHATSAPP_SETTINGS: 'WHATSAPP',
    WORK_ORDER_CEMETERY_ADD: 'ASSIGNAR OT SEPULTURERS (Coord. sepul)',
    WORK_ORDER_CEMETERY_READ: 'VISUALITZAR OTS CEMENTIRI',
    WORK_ORDER_CEMETERY_WRITE: 'REALITZAT OTS CEMENTIRI',
    WORK_ORDER_PERMISSION_GROUP: 'Permisos d\'ordres de treball',
    WORK_ORDER_WORKSHOP_ADD: 'ASSIGNAR OT FUNERARIS (Coord. fune)',
    WORK_ORDER_WORKSHOP_READ: 'VISUALITZAR OTS TALLER',
    WORK_ORDER_WORKSHOP_WRITE: 'REALITZAT OTS TALLER',
  },
  saveKo: 'Error a l\'intentar guardar el rol',
  saveOk: 'El rol ha estat guardat amb èxit!',
  search: 'Cerca',
  sureDelete: 'Estàs segur que vols eliminar aquest paper?',
};

export default role;
