import React, { FC } from 'react';

import CustomIcon from 'components/base/CustomIcon';
import { OtherTypes } from 'components/base/CustomIcon/types';

import { FileManagerItemWrapperComponent } from './types';

export const FileManagerItemWrapper: FC<FileManagerItemWrapperComponent> = (
  { value: folderItem },
) => (
  <div className="f-file-item">
    <div className="icon">
      {folderItem.type === 'folder' && <CustomIcon icon={OtherTypes.FOLDER} />}
      {folderItem.type === 'file' && <CustomIcon icon={OtherTypes.FILE} />}
    </div>
    <div className="name">
      {folderItem.name}
    </div>
    <div className="mime-type">
      {folderItem.mimeType}
    </div>
  </div>
);
