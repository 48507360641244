import { Form, Formik, FormikProps } from 'formik';
import React, {
  FC, useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';

import Button from 'components/base/Button';
import Input from 'components/base/Input';
import Modal from 'components/base/Modal';

import { FileManagerContext } from '../../context';

import { FileManagerRenameModalComponent } from './types';
import './index.scss';

export const FileManagerRenameModal: FC<FileManagerRenameModalComponent> = ({
  onAccept, onCancel, show,
}) => {
  const { t } = useTranslation();
  const { selectedItem } = useContext(FileManagerContext);

  return (
    <Modal
      className="f-filemanager-createfolder-modal"
      closeOnFocusLost={false}
      show={show}
      showCancel={false}
      showFooter={false}
      title={t('record.document.rename')}
      onHide={onCancel}
    >
      {show && selectedItem && (
      <Formik
        initialValues={{
          name: selectedItem.name,
        }}
        validationSchema={object().shape({
          name: string().required(),
        })}
        validateOnBlur
        validateOnChange
        validateOnMount
        onSubmit={(values) => {
          onAccept(values.name);
        }}
      >
        {({
          setFieldValue, values, isValid,
        }: FormikProps<{
    name: string;
  }>) => (
    <Form autoComplete="off">
      <Input
        id="newName"
        name="newName"
        placeholder={t('common.name')}
        type="text"
        value={values.name}
        onChange={(val) => setFieldValue('name', val)}
      />
      <div
        className="buttons-panel"
      >
        <Button
          color="secondary"
          text={t('common.cancel')}
          type="button"
          onClick={onCancel}
        />
        <Button
          color="primary"
          disabled={!isValid}
          text={t('common.accept')}
          type="submit"
        />
      </div>
    </Form>
        )}
      </Formik>

      )}
    </Modal>
  );
};
