import { Base64Data } from 'models/Base64Data';
import { MimeTypes } from 'models/Base64Data/MimeTypes';

export const arrayBufferToBase64 = (
  input: ArrayBuffer,
  mimeType: MimeTypes = MimeTypes.APPLICATION_PDF,
): string => {
  const unit8 = new Uint8Array(input);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const binary = String.fromCharCode.apply(null, unit8 as any);
  const base64 = btoa(binary);
  return `data:${mimeType}${Base64Data.BASE64_GLU}${base64}`;
};
